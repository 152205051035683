import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="respiratorio-arm"
export default class extends Controller {
  static targets = ['checkbox', 'sections', 'modo', 'peep']

  connect() {
    if(this.checkboxTarget.checked) {
      this.sectionsTarget.classList.remove('d-none')
    }
  }

  toggleSections(e) {
    e.preventDefault()

    if (this.checkboxTarget.checked) {
      this.sectionsTarget.classList.remove('d-none')
    } else {
      this.modoTarget.value = null
      this.peepTarget.value = null
      this.sectionsTarget.classList.add('d-none')
    }
  }
}
