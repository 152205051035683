import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import '@popperjs/core';
import * as tempusDominus from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat'
import { load, faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
tempusDominus.extend(customDateFormat)
tempusDominus.extend(load)
import * as moment from 'moment-timezone';

const datepickerConfig = {
  display: {
    components: {
      decades: false,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false,
    }
  },
  localization: {
    locale: 'es',
    format: "dd/MM/yyyy"
  },
  // hooks: {
  //   inputFormat: (_, date) => {
  //     return moment(date).tz('Etc/UTC').format('DD/MM/YYYY')
  //   },
  //   inputParse: (_, date) => {
  //     const momentDate = moment(date, 'DD/MM/YYYY')
  //     return new tempusDominus.DateTime(momentDate.toDate())
  //   }
  // }
}

// Connects to data-controller="indicaciones-medicas-office-picker"
export default class extends Controller {
  static values = {
    url: String,
    readonly: Boolean
  }

  connect() {
    const td = new tempusDominus.TempusDominus(this.element, datepickerConfig)
    if (this.readonlyValue) {
      td.disable()
    }

    this.subscription = td.subscribe(tempusDominus.Namespace.events.hide, (e) => {
      if (e.date !== null && e.date?.getTime() === e.date?.getTime()) {
        get(this.urlValue, {
          responseKind: 'turbo-stream',
          query: {
            current_date: e.date.toISOString().slice(0, 10)
          }
        })
      }
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  test() {
    console.log("🚀 ~ file: indicaciones_medicas_office_picker_controller.js ~ line 64 ~ extends ~ test ~ test")
  }
}
