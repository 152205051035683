import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-hora"
export default class extends Controller {
  static targets = ['hidden', 'seven', 'nineteen']

  connect() {
    if (this.hiddenTarget.value) {
      const date = new Date(this.hiddenTarget.value);

      if (date.getHours() === 19) {
        this.nineteenTarget.click();
      } else {
        this.sevenTarget.click();
      }

    } else {
      this.sevenTarget.click();
    }
  }

  onClickSeven(e) {
    e.preventDefault();
    this.hiddenTarget.value = '07:00'
  }

  onClickNineteen(e) {
    e.preventDefault();
    this.hiddenTarget.value = '19:00'
  }

  _setDateWithHours(hours) {
    let datetime = new Date();
    datetime.setHours(hours);
    datetime.setMinutes(0);
    datetime.setSeconds(0);

    return datetime;
  }
}
