import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

// Connects to data-controller="filter-indicaciones-medicas"
export default class extends Controller {
  static targets = ['allCheckbox'];
  static values = {
    url: String,
  }

  onClickPerformSearch(event) {
    event.preventDefault();

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        all: this.allCheckboxTarget.checked
      }
    })
  }
}
