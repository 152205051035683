import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="protocolo-modelo-linker"
export default class extends Controller {
  static targets = ['input']

  fillInput(e) {
    e.preventDefault();
    const protocoloModelo = JSON.parse(e.target.dataset.protocoloModelo);
    this.inputTarget.value = protocoloModelo.descripcion;
  }
}
