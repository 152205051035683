import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
// Connects to data-controller="filter-consumos-farmacias"
export default class extends Controller {
  static targets = ["fecha", "fechaHasta"]

  static values = {
    url: String,
    registro: String
  }

  onChangeFilter(e){
    e.preventDefault()

    if (this.fechaTarget.value && this.fechaHastaTarget.value) {
      get(this.urlValue, {
        responseKind: 'turbo-stream',
        query: {
          Registro: this.registroValue,
          fecha: this.fechaTarget.value,
          fecha_hasta: this.fechaHastaTarget.value
        }
      })
    }
  }
}
