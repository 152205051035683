import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drogas-vasoactivas"
export default class extends Controller {
  static targets = ['checkbox', 'section', 'droga1', 'cantidad1', 'droga2', 'cantidad2', 'droga3', 'cantidad3', 'droga4', 'cantidad4']

  connect() {
    if(this.checkboxTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    }
  }

  toggleSection(e) {
    e.preventDefault()

    if (this.checkboxTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    } else {
      this.droga1Target.value = null
      this.cantidad1Target.value = null
      this.droga2Target.value = null
      this.cantidad2Target.value = null
      this.droga3Target.value = null
      this.cantidad3Target.value = null
      this.droga4Target.value = null
      this.cantidad4Target.value = null
      this.sectionTarget.classList.add('d-none')
    }
  }
}
