import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash";

// Connects to data-controller="inplace"
export default class extends Controller {
  static targets = ['cuadroEvolucionInput', 'oximetroInput', 'capnografiaInput', 'template', 'target', 'cuadroDrogasInput'];
  static values = {
    readonly: Boolean
  }

  async connect() {
    const cuadroEvolucion = this.cuadroEvolucionInputTarget.value ? await JSON.parse(this.cuadroEvolucionInputTarget.value) : {};
    const cuadroDrogas = this.cuadroDrogasInputTarget.value ? await JSON.parse(this.cuadroDrogasInputTarget.value) : {};
    const oximetro = this.oximetroInputTarget.value ? await JSON.parse(this.oximetroInputTarget.value) : {};
    const capnografia = this.capnografiaInputTarget.value ? await JSON.parse(this.capnografiaInputTarget.value) : {};

    this.cuadroEvolucionValue = this._parseValue(cuadroEvolucion)
    this.cuadroDrogasValue    = this._parseValue(cuadroDrogas)
    this.oximetroValue        = this._parseValue(oximetro)
    this.capnografiaValue     = this._parseValue(capnografia)

    this._populateValues();
  }

  _parseValue(value) {
    if (isEmpty(value) || value === "") {
      return {};
    } else if (Object.prototype.toString.call(value) === '[object Object]') {
      return value;
    } else {
      return JSON.parse(value);
    }
  }
  
  changeCuadro(e) {
    e.preventDefault()

    e.preventDefault()

    const data = this._dataExtractor(e);
    if (data['button'].dataset['readonly'] === 'true') return;

    const kindOfValue = this._iconSwitcher(data)


    if (kindOfValue === 'should-remove') {
      delete this.cuadroEvolucionValue[`${data['vertical']}-${data['horizontal']}`]
    } else {
      this.cuadroEvolucionValue[`${data['vertical']}-${data['horizontal']}`] = {
        type: kindOfValue,
        value: data['vertical']
      }
    }

    this.cuadroEvolucionInputTarget.value = JSON.stringify(this.cuadroEvolucionValue);
  }

  changeDrugsCuadro(e) {
    e.preventDefault();

    const data = this._dataExtractor(e);

    // branch para manejar el evento del input
    if (e.type == "blur") {
      const drugKey = e.target.value;
      const currentValue = e.target.dataset['currentValue']

      if (!currentValue) {
        e.target.dataset['currentValue'] = drugKey;
        this.cuadroDrogasValue[drugKey] = {};
      } else if (currentValue && (currentValue !== drugKey)) {
        const valuesWithOldKey = this.cuadroDrogasValue[currentValue];
        this.cuadroDrogasValue[drugKey] = valuesWithOldKey;
        delete this.cuadroDrogasValue[currentValue];
      }

    } else {
      // prevent click si el input no tiene nada
      const drugKey = e.target.closest('.drug-row').children[0].children[0].dataset['currentValue'];
      const valueKey = data.horizontal;

      if (data['button'].dataset['readonly'] === 'true' || !drugKey) return;
      const kindOfValue = this._iconSwitcher(data);

      if (kindOfValue === 'should-remove') {
        delete this.cuadroDrogasValue[drugKey][valueKey]
      } else {
        this.cuadroDrogasValue[drugKey][valueKey] = {
          value: valueKey
        }
      }
    }

    this.cuadroDrogasInputTarget.value = JSON.stringify(this.cuadroDrogasValue);
  }

  changeOximetro(e) {
    e.preventDefault();

    if (e.target.value) {
      this.oximetroValue[e.target.dataset['timePosition']] = e.target.value;
    } else {
      delete this.oximetroValue[e.target.dataset['timePosition']];
    }

    this.oximetroInputTarget.value = JSON.stringify(this.oximetroValue);
  }

  changeCapnografia(e) {
    e.preventDefault();

    if (e.target.value) {
      this.capnografiaValue[e.target.dataset['timePosition']] = e.target.value;
    } else {
      delete this.capnografiaValue[e.target.dataset['timePosition']];
    }

    this.capnografiaInputTarget.value = JSON.stringify(this.capnografiaValue);
  }

  _dataExtractor(event) {
    if (event.target.nodeName.toLowerCase() === 'button') {
      return {
        button: event.target,
        horizontal: event.target.dataset['horizontalValue'],
        vertical: event.target.dataset['verticalValue'],
        element: event.target.firstChild,
        currentClass: event.target.firstChild.classList[1],
        isDrug: !!event.target.dataset['isDrug']
      }
    } else {
      return {
        button: event.target.parentNode,
        horizontal: event.target.parentNode.dataset['horizontalValue'],
        vertical: event.target.parentNode.dataset['verticalValue'],
        element: event.target,
        currentClass: event.target.classList[1],
        isDrug: !!event.target.dataset['isDrug']
      }
    }
  }

  _iconSwitcher(data) {
    let kindOfValue;
    if (data.isDrug) {
      if (!data['currentClass']) {
        data['element'].classList.add('fa-check');
        kindOfValue = '';
      } else if (data['currentClass'] === 'fa-check') {
        data['element'].classList.remove('fa-check');
        kindOfValue = 'should-remove';
      }
    } else {
      if (data['currentClass'] === 'fa-angle-up') {
        data['element'].classList.remove('fa-angle-up');
        data['element'].classList.add('fa-circle');
        kindOfValue = 'frecuencia_respiratoria';
      } else if (data['currentClass'] === 'fa-circle') {
        data['element'].classList.remove('fa-circle');
        data['element'].classList.add('fa-check');
        kindOfValue = 'sistolica'
      } else if (data['currentClass'] === 'fa-check') {
        data['element'].classList.remove('fa-check');
        kindOfValue = 'should-remove';
      } else {
        data['element'].classList.add('fa-angle-up');
        kindOfValue = 'diastolica'
      }
    }

    return kindOfValue;
  }

  addDrug(e, inputValue, buttonKeys) {
    e.preventDefault();
    if (inputValue && buttonKeys) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.templateTarget.innerHTML;

      tempElement.querySelector("#drugName").setAttribute("value", inputValue);
      tempElement.querySelector("#drugName").setAttribute("data-current-value", inputValue);
      const buttons = tempElement.querySelectorAll('.drug-buttons button');

      buttons.forEach(button => {
        const horizontalValue = button.dataset['horizontalValue'];

        if(buttonKeys.includes(horizontalValue)) {
          button.children[0].classList.add('fa-check');
        }
      });

      this.targetTarget.insertAdjacentHTML('beforebegin', tempElement.innerHTML);
    } else {
      const htmlString = this.templateTarget.innerHTML;
      this.targetTarget.insertAdjacentHTML('beforebegin', htmlString);
    }

  }

  _populateValues() {
    if (!isEmpty(this.cuadroEvolucionValue)) {
      Object.keys(this.cuadroEvolucionValue).forEach(key => {
        const value = this.cuadroEvolucionValue[key];
        const element = document.getElementById(`cuadro-${key}`).firstChild;
        if (value.type === 'frecuencia_respiratoria') {
          element.classList.add('fa-circle');
        } else if (value.type === 'sistolica') {
          element.classList.add('fa-check');
        } else if (value.type === 'diastolica') {
          element.classList.add('fa-angle-up');
        }
      });
    }

    if (!isEmpty(this.cuadroDrogasValue)) {
      Object.keys(this.cuadroDrogasValue).forEach(key => {
        this.addDrug(new Event('click'), key, Object.keys(this.cuadroDrogasValue[key]));
      })
    }

    if (!isEmpty(this.oximetroValue)) {
      Object.keys(this.oximetroValue).forEach(key => {
        const value = this.oximetroValue[key];
        const element = document.getElementById(`oximetro_${key}`);
        element.value = value;
      })
    }

    if (!isEmpty(this.capnografiaValue)) {
      Object.keys(this.capnografiaValue).forEach(key => {
        const value = this.capnografiaValue[key];
        const element = document.getElementById(`capnografia_${key}`);
        element.value = value;
      })
    }
  }
}
