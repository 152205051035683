import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"


// Connects to data-controller="toggle-carpeta-revisada"
export default class extends Controller {
  static values ={
    updateUrl: String,
  }

  toggle(event) {
    patch(this.updateUrlValue, {
      responseKind: "turbo-stream"
    })
  }
}
