import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { isEqual, omit } from "lodash";

// Connects to data-controller="indicaciones-medicas-office"
export default class extends Controller {
  static values = {
    registro: Number,
    hoja: Number,
    currentDate: String,
    currentUser: String
  }

  initialize() {
    this.indicacionesOffice = [];
  }

  connect() {
    if (this.element.dataset.indicacionesOffice.length) {
      const indicacionesOffice = JSON.parse(this.element.dataset.indicacionesOffice)
      indicacionesOffice.forEach(indicacionOffice => {
        const parsedIndicacion = JSON.parse(indicacionOffice)
        const horaColoc = new Date(parsedIndicacion.HoraColoc)
        const horaColocString = horaColoc.getHours().toString() === '0' ? '00' : horaColoc.getHours().toString()

        const indicacionMedicaOffice = {
          MaterialCodigo: parsedIndicacion.MaterialCodigo,
          medicamento: parsedIndicacion.medicamento,
          Interno: parsedIndicacion.Interno,
          HoraColoc: horaColocString,
          Fecha: parsedIndicacion.Fecha,
          Usuario: parsedIndicacion.Usuario || this.currentUserValue,
          destroyable: false
        }

        this.indicacionesOffice.push(indicacionMedicaOffice);
      });
    }

    this.indicacionesOffice.forEach(indicacionOffice => {
      document.getElementById(`${indicacionOffice.MaterialCodigo || indicacionOffice.medicamento}-${indicacionOffice.Interno}-${indicacionOffice.HoraColoc}`).checked = true;
    })
  }

  setIndicacionOffice(e) {
    e.preventDefault();

    const indicacionMedicaDetalle = JSON.parse(e.target.dataset.indicacionMedicaDetalle);
    const horaColoc = e.target.value;
    const indicacionMedicaOffice = {
      MaterialCodigo: indicacionMedicaDetalle.MaterialCodigo,
      Interno: indicacionMedicaDetalle.Interno,
      HoraColoc: horaColoc,
      Usuario: this.currentUserValue,
      Fecha: this.currentDateValue,
      destroyable: true
    }

    const indicacionOfficeIndex = this.indicacionesOffice.findIndex(ind => isEqual(omit(ind, ['Usuario']), omit(indicacionMedicaOffice, ['Usuario'])));

    if (indicacionOfficeIndex > -1) {
      this.indicacionesOffice[indicacionOfficeIndex].destroyable = true;
    } else {
      this.indicacionesOffice.push(indicacionMedicaOffice);
    }
  }

  submit(e) {
    e.preventDefault();

    post('/indicaciones_medicas_office/batch_create', {
      responseKind: 'turbo-stream',
      body: {
        Registro: this.registroValue,
        Hoja: this.hojaValue,
        current_date: this.currentDateValue,
        indicaciones_medicas_office: this.indicacionesOffice
      }
    })
  }
}
