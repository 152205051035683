import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="filter-profesionales-prestaciones"
export default class extends Controller {
  static values = {
    url: String,
    interno: String,
    movimiento: String
  }

  prestacionSelected(e) {
    e.preventDefault();
    console.log(" n° interno", this.internoValue )
    console.log(" n° mov", this.movimientoValue )
    console.log("instancia", this.instanceValue)
    
    const instanceData = JSON.stringify(this.instanceValue);
    console.log("instancia nueva", instanceData)

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        numero_interno: this.internoValue || null,
        numero_movimiento: this.movimientoValue || null
      }
    })
  }

  prestacionNew(e) {
    e.preventDefault();
  }
}
