import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-vias"
export default class extends Controller {
  static targets = ['section']
  toggle(e) {
    e.preventDefault();

    const result = this.sectionTarget.classList.toggle('d-none');
    if (result) {
      e.target.innerText = 'Ver Vias';
    } else {
      e.target.innerText = 'Esconder Vias';
    }
  }
}
