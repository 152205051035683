import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="buscar-reportes"
export default class extends Controller {
  static targets = ["desde", "hasta"]
  static values = {
    url: String,
    registro: Number
  }

  imprimir(e) {
    e.preventDefault()

    get(`${this.urlValue}?registro=${this.registroValue}&desde=${this.desdeTarget.value}&hasta=${this.hastaTarget.value}`, { responseKind: 'turbo-stream' })  }
}
