import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"


// Connects to data-controller="prestaciones-action"
export default class extends Controller {
  static values = {
    numeroInternacion: Number,
  }

  initialize() {
    this.selectedPrestaciones = [];
  }

  setPrestacion(e) {
    e.preventDefault();

    const numeroInterno = e.target.value;

    if (e.target.checked) {
      this.selectedPrestaciones.push(numeroInterno);
    } else {
      const index = this.selectedPrestaciones.findIndex(p => p === numeroInterno);
      if (index !== -1) {
        this.selectedPrestaciones.splice(index, 1);
      }
    }

    const autorizarButton = document.getElementById("btnAutorizar");

    if(this.selectedPrestaciones.length != 0) {
      autorizarButton.classList.remove('disabled');
    } else {
      autorizarButton.classList.add('disabled');
    }

    if (e.target.dataset.respuesta != null) {

      const consumirButton = document.getElementById("btnConsumir");
      const respuesta = JSON.parse(e.target.dataset.respuesta)

      if ((this.selectedPrestaciones.length != 0) && (respuesta.DescripcionEstado.includes("AUTORIZADA"))) {
        consumirButton.classList.remove('disabled');
      } else {
        consumirButton.classList.add('disabled');
      }
    }

  }

  autorizar(e) {
    e.preventDefault();

    post('/prestaciones/autorizar', {
      responseKind: 'turbo-stream',
      query: {
        numero_internacion: this.numeroInternacionValue,
        prestaciones_ids: this.selectedPrestaciones
      }
    })
  }

  consumir(e) {
    e.preventDefault();

    post('/prestaciones/consumir', {
      responseKind: 'turbo-stream',
      query: {
        numero_internacion: this.numeroInternacionValue,
        prestaciones_ids: this.selectedPrestaciones
      }
    })
  }
}
