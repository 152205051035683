import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prevent-return-key"
export default class extends Controller {

  onReturnPressKey(e) {
    if (e.keyCode === 13 && e.target?.nodeName && e.target.nodeName !== 'TEXTAREA'){
      e.preventDefault();
    }
  }
}
