import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="filter-prestaciones"
export default class extends Controller {
  static targets = ['codigoPractica', 'codigoPrestacion']
  static values = {
    url: String
  }

  internacionSelected(e) {
    const selectedOption = e.currentTarget.getAttribute('data-autocomplete-value')
    const parts = selectedOption.split("-")
    const numeroInternacion = parts[0].trim()

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        numero_internacion: numeroInternacion
      }
    })
  }

  prestacionSelected(e) {
    const codigoSelectedOption = e.currentTarget.getAttribute('data-filter-prestaciones-codigo-value')
    const parts = codigoSelectedOption.split("/")
    const codigoPrestacion = parts[0]
    const codigoPractica = parts[1]

    this.codigoPrestacionTarget.value = codigoPrestacion
    this.codigoPracticaTarget.value = codigoPractica
  }
}
