import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="epicrisis-arm"
export default class extends Controller {
  static targets = ['checkbox', 'section', 'dias', 'fechaExtubacion']

  connect() {
    if(this.checkboxTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    }
  }

  toggleSection(e) {
    e.preventDefault()

    if (this.checkboxTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    } else {
      this.diasTarget.value = null
      this.fechaExtubacionTarget.value = null
      this.sectionTarget.classList.add('d-none')
    }
  }
}
