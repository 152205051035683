import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master-password-auditores"
export default class extends Controller {
  static targets = ['hiddenSection', 'passwordInput'];

  connect() {
    this.hiddenSectionTarget.classList.add('d-none')
  }

  enableObrasSociales(e) {
    e.preventDefault();

    if (this.passwordInputTarget.value && this.passwordInputTarget.value === 'ClaveAdminSuperSecreta') {
      e.target.classList.add('d-none')
      this.passwordInputTarget.classList.add('d-none');
      this.hiddenSectionTarget.classList.remove('d-none');
    }
  }
}
