import { Controller } from "@hotwired/stimulus"
import 'toastr/build/toastr.css'
import * as toastr from 'toastr'

// Connects to data-controller="toastr"
export default class extends Controller {
  static values = {
    message: String,
    isAlert: Boolean,
  }

  connect() {
    toastr.options.closeButton = true
    toastr.options.closeDuration = 500

    if (this.messageValue) {
      if (this.isAlertValue) {
        toastr.error(this.messageValue)
      } else {
        toastr.success(this.messageValue)
      }
    }
  }
}
