import { Controller } from "@hotwired/stimulus"
import "tom-select/dist/js/tom-select.complete"

// Connects to data-controller="tom-select"
export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      plugins: {
        remove_button: {
          title: 'Quitar obra social'
        }
      },
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      placeholder: 'Seleccionar obras sociales',
      searchField: 'text',
      render: {
        no_results: function (data, escape) {
          return '<div class="no-results"> No hay resultados para la búsqueda</div>';
        }
      }
    })

  }

  disconnect() {
    if (this.select) this.select.destroy();
  }
}
