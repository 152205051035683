import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ['checkbox', 'section', 'textfield']

  connect() {
    if (this.checkboxTarget.checked) this.sectionTarget.classList.remove('d-none');
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.sectionTarget.classList.remove('d-none');
    } else {
      this.sectionTarget.classList.add('d-none')
      this.textfieldTarget.value = null;
    }
  }
}
