import { Controller } from "@hotwired/stimulus"
import { get, destroy } from '@rails/request.js'

// Connects to data-controller="turbo"
export default class extends Controller {
  initialize() {
    this.element.setAttribute('data-action', 'click->turbo#click')
  }

  click(e) {
    e.preventDefault()
    this.url = this.element.getAttribute('href')

    if (this.element.dataset.method === 'delete') {
      destroy(this.url, {
        responseKind: 'turbo-stream'
      })
    } else {
      get(this.url, {
        responseKind: 'turbo-stream'
      })
    }
  }
}
