import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, { backdrop: 'static', keyboard: true })
    this.modal.show()
  }

  disconnect() {
    this.modal.hide()
  }

  dismiss(e) {
    if (e.detail.success) this.modal.hide();
  }
}
