import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

// Connects to data-controller="search-pacientes"
export default class extends Controller {
  static targets = ['searchInput', 'allCheckbox', 'sector', 'obraSocial'];
  static values = {
    url: String
  }

  onClickPerformSearch(event) {
    event.preventDefault();
    let sectorValue = null;
    let obraSocialValue = null;

    if (this.sectorTarget.value && this.sectorTarget.value !== 'Seleccione un sector') {
      sectorValue = this.sectorTarget.value;
    }

    if (this.obraSocialTarget.value && this.obraSocialTarget.value !== 'Seleccione una obra social') {
      obraSocialValue = this.obraSocialTarget.value;
    }

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        q: this.searchInputTarget.value || null,
        all: this.allCheckboxTarget.checked,
        sector: sectorValue,
        obra_social: obraSocialValue
      }
    })
  }
}
