import { Controller } from "@hotwired/stimulus"
import { post, get } from "@rails/request.js"

// Connects to data-controller="indicaciones-medicas"
export default class extends Controller {
  static targets = [
    'registro',
    'hoja',
    'usuario',
    'matricula',
    'codigoMaterial',
    'material',
    'intervalo',
    'cantidad',
    'observaciones',
    'interno',
    'estado'
  ];
  static values = {
    url: String
  }

  submitForms(e) {
    e.preventDefault();

    post(this.urlValue, {
      responseKind: 'turbo-stream',
      body: {
        Registro: this.registroTarget.value,
        Hoja: this.hojaTarget.value,
        indicacion_medica: {
          Registro: this.registroTarget.value,
          Hoja: this.hojaTarget.value,
          Usuario: this.usuarioTarget.value,
          Matricula: this.matriculaTarget.value,
        },
        indicacion_medica_detalle: {
          Interno: this.internoTarget.value,
          MaterialCodigo: this.codigoMaterialTarget.value,
          Registro: this.registroTarget.value,
          Hoja: this.hojaTarget.value,
          Intervalo: this.intervaloTarget.value,
          Cantidad: this.cantidadTarget.value,
          Observaciones: this.observacionesTarget.value,
          Estado: this.estadoTarget.value
        }
      }
    })
  }

  populateForm(e) {
    e.preventDefault();
    const indicacionMedica = JSON.parse(e.target.dataset.indicacionMedicaDetalle);
    this.__setProperties(indicacionMedica);
  }

  onCleanForm(e) {
    e.preventDefault();
    this.__setProperties();
  }

  async __setProperties(indicacionMedicaDetalle = null) {
    if (indicacionMedicaDetalle) {
      if (indicacionMedicaDetalle.MaterialCodigo) {
        await this.__fetchAndParseMaterial(indicacionMedicaDetalle.MaterialCodigo)
      }
      this.internoTarget.value = indicacionMedicaDetalle.Interno;
      this.codigoMaterialTarget.value = indicacionMedicaDetalle.MaterialCodigo;
      this.intervaloTarget.value = indicacionMedicaDetalle.Intervalo;
      this.cantidadTarget.value = indicacionMedicaDetalle.Cantidad;
      this.observacionesTarget.value = indicacionMedicaDetalle.Observaciones;
      this.estadoTarget.value = indicacionMedicaDetalle.Estado || 'ACTIVO';
    } else {
      this.internoTarget.value = null;
      this.codigoMaterialTarget.value = null;
      this.intervaloTarget.value = null;
      this.cantidadTarget.value = null;
      this.observacionesTarget.value = null;
      this.materialTarget.value = null;
      this.estadoTarget.value = 'ACTIVO';
    }
  }

  async __fetchAndParseMaterial(codigoMaterial) {
    const response = await get('/materiales/material_details', {
      responseKind: 'json',
      query: {
        codigo: codigoMaterial
      }
    });

    if (response.ok) {
      const material = JSON.parse(await response.text);
      if (material) this.materialTarget.value = `${material.CodigoMaterial} - ${material.Descripcion}`;
    }
  }
}
